<template>
	<div class="page container">
		<v2-back-btn />
		<div class="page__info">
			<div class="page__info--title">
				Lesson details
			</div>
		</div>

		<lesson-edit
			entryRoute="admin"
		/>

	</div>
</template>

<script>
	import LessonEdit   from '@/components/lessons/LessonEdit';
	import routeParams  from '@/mixins/routeParams';
	import V2BackBtn          from '@/components/v2/ui/V2BackBtn';

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Lesson details'
		},
		components: {
			LessonEdit,
			V2BackBtn
		},
		mixins: [routeParams],
		data: () => ({
			lesson: {
				title:            '',
				description:      '',
				introduction:     ''
			}
		}),
		computed: {
			getCourseRoute () {
				if (!this.getCourseId) {
					return false;
				}
				return `/admin/courses/${this.getCourseId}`;
			},
			getLessonRoute () {
				if (!this.getLessonId) {
					return false;
				}
				return `${this.getCourseRoute}/lessons/`;
			},
			getBreadcrumbRoute () {
				return this.getLessonRoute;
			}
		}
	};
</script>
